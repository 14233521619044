<template>
  <div class="game_contain hb-100 wb-100">
    <div class="game_head center wb-100 h-100 relative">
      <div class="back fz-30 t-w pointer" @click="handleBack">
        <i class="el-icon-arrow-left fz-40"></i>
        <span class="ml-17">返回</span>
      </div>
      <div class="fz-30 t-w">{{ title }}</div>
    </div>
    <div class="game_content pt-20 pb-34 pl-68 pr-37">
      <div class="tabs_box align-center wb-100">
        <div
          class="tabs_item c-4 mr-200 pointer h-50 center fz-30"
          v-for="(item, index) in tabsList"
          :key="index"
          :class="{ action: item.value == current }"
          @click="handleTabClick(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="contain mt-30" ref="containRef">
        <template v-if="dataList && dataList.length">
          <div class="align-center wb-100 flex-wrap">
            <div
              class="card pointer ml-10 mr-10 mb-36"
              v-for="(item, index) in dataList"
              :key="index"
              @click="gotolive(item)"
            >
              <div class="wb-100 pl-18 pr-18 pt-14 t-b fz-22">
                  {{ item.school_name }}
              </div>
              <div class="fz-22 wb-100 pl-18 pr-18 pb-14 t-b ">
                ({{ item.start_time  }} - {{ item.end_time  }})
              </div>
              <div class="image_box wb-100 relative">
                <img :src="item.back_pic" alt="" class="wb-100 hb-100" />
                <div
                  class="status_box absolute align-center pl-10 pr-10 pt-4 pb-4"
                  :class="item.status == 1 ? 'box1' : 'box2'"
                >
                  <img
                    v-if="item.status == 1|| item.play_back"
                    :src="`${imageUrl}${
                      item.status == 1 ? 'live-003.png' : 'live-004.png'
                    }`"
                    alt=""
                    :class="item.status == 1 ? 'w-13 h-21' : 'w-24 h-24'"
                  />
                  <div class="ml-5 fz-22 t-w">
                    {{ item.status == 1 ? "直播中" : item.play_back ? "查看回放" : "已结束" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-empty
          description="暂无数据"
          v-else
          class="mt-100 pt-100"
        ></el-empty>
      </div>
      <!-- <div class="mt-20 wb-100 row-end" v-if="dataList && dataList.length">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :page-size="page.pageSize"
          :current-page="page.page"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div> -->
      <div class="btn_box wb-100 center h-80 mt-20" v-if="gameStatus == 1">
        <div
          class="btn h-80 center t-w fz-30 pointer br-40"
          @click="getliveUrl()"
        >
          开始直播
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "../common/common";
export default {
  data() {
    return {
      dataList: [],
      imageUrl,
      current: "",
      title: "",
      tabsList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "正在直播",
          value: 1,
        },
        {
          label: "已结束",
          value: 2,
        },
      ],
      match_id: "",
      gameStatus: "",
      page: {
        page: 1,
        pageSize: 1000,
        total: -1,
      },
      liveUrl: {},
    };
  },
  created() {
    this.getQuery();

    this.getList();
  },
  methods: {
    gotolive(item) {
      if (item.status == 2) {
        if (!item.play_back) return this.$message.warning("暂无回放视频");
        window.localStorage.setItem('back_pic', item.back_pic)
        this.$router.push({
          path: "/video-list",
          query: {
            id: item.id,
            title: this.title,
            school_name: item.school_name
          },
        });
      } else {
        // this.getliveUrl();
        // 观看其他的直播
        window.localStorage.setItem('pull_url', item.pull_url)
        this.$router.push({
          path: "/pull-video",
          query: {
            title: this.title,
          },
        });
      }
    },

    async getliveUrl() {
      const { match_id } = this;
      const admin = JSON.parse(localStorage.getItem("schoolInfo"));
      const params = {
        match_id,
        school_id: admin.school_id,
        user_type: admin.user_type,
      };

      const { data } = await this.$post("/match/school/url", params);
      this.liveUrl = data;
      window.localStorage.setItem(
        "pushUrl",
        encodeURIComponent(JSON.stringify(this.liveUrl.push_url))
      );

      this.$confirm("此操作将开启直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({
            path: "/live",
            query: {
              id: this.liveUrl.id,
              match_id,
              title: this.title,
            },
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    handlePageChange(page) {
      this.page.page = page;
      this.$refs.containRef.scrollTop = 0;
      this.getList();
    },
    handleBack() {
      this.$router.go(-1);
    },
    getQuery() {
      const { match_id, title, status } = this.$route.query;
      this.match_id = match_id;
      this.title = title;
      this.gameStatus = status;
    },
    handleTabClick(value) {
      this.current = value;
      this.page.page = 1;
      this.getList();
    },
    async getList() {
      const {
        page: { page, pageSize, total },
        current,
        match_id,
      } = this;
      const params = {
        match_id,
        per_page: pageSize,
        page,
      };
      if (current) {
        params.status = current;
      }
      const { data } = await this.$get("/match/school/liveList", params);
      this.dataList = data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.h-200 {
  height: 200px;
}
.back {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.game_contain {
  background-color: #f5f6fa;
  .game_head {
    background-color: #212526;
  }
  .LogOut {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .mr-200 {
    margin-right: 200px;
  }

  .action {
    color: #226bff;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 6px;
      background-color: #226bff;
      border-radius: 3px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .contain {
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .card {
    width: 418px;
    min-height: 272px;
    background: #ffffff;
    box-shadow: 4px 16px 30px 1px rgba(102, 169, 255, 0.4);
    .image_box {
      height: 217px;
    }
  }

  .status_box {
    height: 37px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .box1 {
    background-color: #fd1041;
  }

  .box2 {
    background-color: #226bff;
  }
}

.btn {
  width: 570px;
  background-color: #fd1041;
}
</style>
